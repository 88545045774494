import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { arrowCauruselPosition } from "../../types";
import { Box } from "../Box";

export const Container = styled(Box)`
  width: 100%;
  transition: 500ms all;
  scroll-behavior: smooth;
`;

export const CustomCarousel = styled.div`
  display: flex;
  gap: 20px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: auto;
  overflow-y: hidden;

  @media screen and (max-width: 900px) {
    gap: 16px;
  }

  @media screen and (max-width: 767px) {
    gap: 12px;
  }
  & > div {
    flex: 0 0 calc((100% / 5) - (4 * 20px / 5));
    scroll-snap-align: start;
    scroll-snap-stop: normal;

    @media screen and (max-width: 900px) {
      flex: 0 0 calc((100% / 3) - (2 * 16px / 3));
    }

    @media screen and (max-width: 767px) {
      flex: 0 0 calc((100% / 2) - (12px / 2));
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const BoxArrow = styled.div<arrowCauruselPosition>`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -60px;
  cursor: pointer;
  right: ${({ isRight }): string => (isRight ? "0" : "calc(100% - 30px)")};
  @media screen and (min-width: 770px) {
    &:hover {
      opacity: 0.6;
    }
  }
  @media screen and (max-width: 767px) {
    bottom: -50px;
    opacity: ${({
      isMobilePrevArrowDisabled,
      isMobileNextArrowDisabled,
    }): string => {
      if (isMobileNextArrowDisabled) {
        return isMobileNextArrowDisabled - 2 <= 0 ? "0.6" : "1";
      } else if (isMobilePrevArrowDisabled) {
        return isMobilePrevArrowDisabled ? "0.6" : "1";
      } else {
        return "1";
      }
    }};
  }
  @media screen and (min-width: 768px) and (max-width: 901px) {
    opacity: ${({
      isMobilePrevArrowDisabled,
      isMobileNextArrowDisabled,
    }): string => {
      if (isMobileNextArrowDisabled) {
        return isMobileNextArrowDisabled - 3 <= 0 ? "0.6" : "1";
      } else if (isMobilePrevArrowDisabled) {
        return isMobilePrevArrowDisabled ? "0.6" : "1";
      } else {
        return "1";
      }
    }};
  }
  @media screen and (min-width: 902px) {
    opacity: ${({
      isMobilePrevArrowDisabled,
      isMobileNextArrowDisabled,
    }): string => {
      if (isMobileNextArrowDisabled) {
        return isMobileNextArrowDisabled - 5 <= 0 ? "0.6" : "1";
      } else if (isMobilePrevArrowDisabled) {
        return isMobilePrevArrowDisabled ? "0.6" : "1";
      } else {
        return "1";
      }
    }};
  }
`;
