import styled from "styled-components";

import { Box } from "../Box";
import { Flex } from "../Flex";
import { Icons } from "../Icons";
import { Text } from "../Text";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }): string => theme.colors.black};
  padding: 60px 40px 27px 40px;

  @media screen and (max-width: 1609px) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 1150px) {
    padding: 44px 40px 27px 40px;
  }

  @media screen and (max-width: 767px) {
    position: relative;
    z-index: 10;
    padding: 40px;
    flex-direction: column;
  }
  .logo-svg {
    margin: 0 auto;
  }
`;

export const Logo = styled(Icons.FullLogo)`
  fill: ${({ theme }): string => theme.colors.white};
  margin-bottom: 20px;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`;

export const InfoContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  width: 100%;
  margin-bottom: 47px;

  @media screen and (max-width: 767px) {
    max-width: unset;
    margin-bottom: 70px;
  }
`;

export const Phone = styled.a`
  font-size: 30px;
  color: ${({ theme }): string => theme.colors.white};
  margin: 0;
  text-decoration: none;
  letter-spacing: 0.01em;

  @media screen and (max-width: 600px) {
    font-size: 20px;
    text-decoration: underline;
  }
`;

export const SocialContainer = styled(Flex)`
  align-items: center;
`;

export const SocialLink = styled.a`
  margin: 0 0 0 32px;
  text-decoration: none;

  :hover {
    opacity: 0.5;
  }
`;

export const FacebookIcon = styled(Icons.Facebook)`
  width: 25px;
  height: 25px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const InstagramIcon = styled(Icons.Instagram)`
  width: 25px;
  height: 25px;
  fill: ${({ theme }): string => theme.colors.black};
`;

export const VkIcon = styled(Icons.Vk)`
  width: 25px;
  height: 25px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const TgIcon = styled(Icons.Tg)`
  width: 25px;
  height: 25px;
  fill: ${({ theme }): string => theme.colors.white};
`;

export const MenuContainer = styled(Box)`
  width: 100%;
  max-width: 455px;
  margin-right: 30px;

  @media screen and (max-width: 1609px) {
    max-width: 390px;
    margin-right: 135px;
  }

  @media screen and (max-width: 1150px) {
    max-width: unset;
    margin-right: 52px;
    width: auto;
  }

  @media screen and (max-width: 767px) {
    order: 2;
  }
`;

export const Containers = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1130px;

  @media screen and (max-width: 1609px) {
    flex-direction: column;
    max-width: 465px;
  }

  @media screen and (max-width: 1150px) {
    max-width: unset;
    width: 485px;
  }

  @media screen and (max-width: 767px) {
    width: auto;
  }
  .scrollTop.hidden {
    opacity: 0;
  }
  .scrollTop {
    border-radius: 50%;
    width: 54px;
    height: 54px;
    display: flex;
    z-index: 100;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;

    svg {
      transition: all 0.3s ease;
      height: 18px;
    }

    &:hover svg {
      transform: translateY(-5px);
    }
  }
`;

export const AddressContainer = styled(Flex)`
  max-width: 620px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    padding-top: 10px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 0;
    max-width: unset;
  }
`;

export const AddressList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Address = styled(Text)`
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: ${({ theme }): string => theme.colors.white};
  white-space: pre-line;
  width: 46%;
  margin-bottom: 60px;

  span {
    display: block;
  }

  a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;

    &:hover {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: 1609px) {
    width: 47%;
    font-size: 14px;
  }

  @media screen and (max-width: 767px) {
    width: auto;
    margin-bottom: 30px;
  }
`;

export const SubscribeContainer = styled(Box)`
  max-width: 408px;
  width: 100%;
`;

export const Copyright = styled(Text)`
  display: block;
  color: ${({ theme }): string => theme.colors.grey};
  font-size: 12px;
  margin-top: 60px;

  @media screen and (max-width: 767px) {
    margin: 27px 0 0 0;
  }
`;

export const AgreementContainer = styled(Box)`
  color: ${({ theme }): string => theme.colors.grey};
  font-size: 12px;
  line-height: 18px;
  margin-top: 21px;
`;

export const AgreementUrl = styled.a`
  color: ${({ theme }): string => theme.colors.grey};
  font-size: 12px;
  text-decoration: underline;
  margin: 0;
  white-space: nowrap;

  :hover {
    color: ${({ theme }): string => theme.colors.white};
  }
`;
