import { Link } from "react-scroll";
import styled from "styled-components";

import { Box } from "src/components/Box";
import { Button } from "src/components/Button";

import { AlphabetProps, LetterItemProps } from "./Brands.types";

export const Container = styled.main`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 30px 105px 10px 105px;
  position: relative;

  @media (max-width: 1440px) {
    padding: 30px 70px 10px 70px;
  }

  @media (max-width: 1200px) {
    padding: 30px 40px 10px 40px;
  }

  @media (max-width: 768px) {
    padding: 30px 10px 10px 30px;
  }

  @media (max-width: 720px) {
    padding: 30px 10px 10px 30px;
  }
`;

export const BrandNav = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  z-index: 90;

  @media (max-width: 768px) {
    top: 18px;
  }
`;

export const BrandHeader = styled.h1`
  font-family: Formular;
  font-weight: 500;
  font-size: 62px;
  line-height: 76px;
  text-align: center;
  letter-spacing: 0.01em;
  margin: 0 0 40px;

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 59px;
    margin: 0 0 23px;
  }
`;

export const BrandFilters = styled(Box)`
  width: 100%;
  max-width: 1110px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin: 0 auto 42px;

  @media (max-width: 1200px) {
    max-width: 880px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 720px) {
    max-width: 355px;
  }
`;

export const BrandButton = styled(Button)`
  width: 270px;
  height: 50px;
  border-width: 2px;

  @media (max-width: 1200px) {
    width: 240px;
  }

  @media (max-width: 768px) {
    max-width: 228px;
    width: 100%;
  }

  @media (max-width: 720px) {
    max-width: 105px;
    height: 30px;
    line-height: normal;
  }
`;

export const LettersList = styled.ul`
  margin: 0 auto 7px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  max-width: 1320px;

  @media (max-width: 1440px) {
    max-width: 1120px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Alphabet = styled.div<AlphabetProps>`
  position: ${({ scrollPosition }): string =>
    scrollPosition > 290 ? "fixed" : "relative"};
  top: ${({ scrollPosition, isWayDown }): string =>
    scrollPosition > 290 ? (isWayDown ? "0" : "70px") : "unset"};
  width: 100%;
  left: 0;
  height: max-content;
  background: ${({ theme }) => theme.colors.white};
  transition: all 0.3s linear 0s;
  z-index: 90;

  @media (max-width: 768px) {
    position: sticky;
    top: 110px;

    left: unset;
    right: 15px;
    width: 50px;
    z-index: 5;
  }
`;

export const BrandContainer = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 50px;
  }
`;

export const LetterItem = styled(Link)<LetterItemProps>`
  font-family: Formular, sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.01em;

  color: ${({ theme, isActive, isdisabled }): string =>
    isActive
      ? theme.colors.lime
      : isdisabled
      ? theme.colors.grey
      : theme.colors.black};
  transition: 0.3s ease color;
  cursor: pointer;

  &.active,
  &:hover {
    color: ${({ theme, isdisabled }): string =>
      isdisabled ? theme.colors.grey : theme.colors.grey};
  }

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 27px;

    &:hover {
      color: ${({ theme, isdisabled }): string =>
        isdisabled ? theme.colors.grey : theme.colors.black};
    }
    &.active {
      color: ${({ theme, isdisabled }): string =>
        isdisabled ? theme.colors.grey : theme.colors.lime};
    }
  }

  @media (max-width: 720px) {
    font-size: 18px;
    line-height: 18px;
  }
`;
