import React, { FC, useCallback, useRef } from "react";

import CarouselNextArrow from "../../components/Carousel/CarouselNextArrow";
import CarouselPrevArrow from "../../components/Carousel/CarouselPrevArrow";

import {
  Container,
  CustomCarousel as MyCarousel,
} from "./CustomCarousel.styles";
import { CarouselProps } from "./CustomCarousel.types";

const CustomCarousel: FC<CarouselProps> = (props) => {
  const { className, children } = props;
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleScrollRight = useCallback(() => {
    if (carouselRef.current)
      carouselRef.current.scrollBy({
        left: -carouselRef.current.getBoundingClientRect().width - 20,
        behavior: "smooth",
      });
  }, [carouselRef]);

  const handleScrollLeft = useCallback(() => {
    if (carouselRef.current)
      carouselRef.current.scrollBy({
        left: carouselRef.current.getBoundingClientRect().width + 20,
        behavior: "smooth",
      });
  }, [carouselRef]);

  return (
    <Container className={className}>
      <MyCarousel ref={carouselRef}>{children}</MyCarousel>
      <CarouselNextArrow onClick={handleScrollLeft} />
      <CarouselPrevArrow
        onClick={handleScrollRight}
        slideCount={5}
        currentSlide={2}
      />
    </Container>
  );
};

export default CustomCarousel;
