import React, { useState, useCallback, FC, useEffect, useRef } from "react";
import { MOUSE_ACTIVATION, TOUCH_ACTIVATION } from "react-image-magnifiers";

import { useIsMobile } from "src/hooks";
import { convertImageUrl } from "src/utils";

import {
  ModalContainer,
  CloseIcon,
  CloseButton,
  ThumbnailList,
  ThumbnailImage,
  Thumbnail,
  MagnifierWrapper,
  ImageWrapper,
  MagnifierContainer,
  MockContainerClick,
} from "./ImageModal.styles";
import { ImageModalProps } from "./ImageModal.types";

const ImageModal: FC<ImageModalProps> = (props) => {
  const [zoom] = useState(false);
  const imageRefs = useRef<Array<HTMLDivElement | null>>([]);

  const { onClose, images, initialImageId = 0 } = props;
  const [currentImage, setCurrentImage] = useState(
    convertImageUrl(images[0], 1920, 1920)
  );
  const [imageId, setImageId] = useState(initialImageId);
  const { isMobile } = useIsMobile();

  const handleChangeImg = useCallback(
    (idx: number) => {
      setImageId(idx);
    },
    [setImageId]
  );

  const handleChangeImgMobile = (isTop: boolean) => {
    if (isTop && imageId > 0) setImageId(imageId - 1);
    else if (!isTop && imageId + 1 < images.length) setImageId(imageId + 1);
  };

  const handleScrollToImg = (img: string) => {
    if (imageRefs.current) {
      const scrollImg = imageRefs.current.find((image) =>
        image?.classList.contains(convertImageUrl(img, 1920, 1920))
      );
      if (scrollImg) {
        scrollImg.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const classNames = (entry.target as HTMLImageElement).className.split(
              " "
            );
            setCurrentImage(classNames[classNames.length - 1]);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    imageRefs.current.forEach((image) => {
      if (image) {
        observer.observe(image);
      }
    });

    return () => {
      imageRefs.current.forEach((image) => {
        if (image) {
          observer.unobserve(image);
        }
      });
    };
  }, []);

  return (
    <ModalContainer onClose={onClose} zoom={zoom}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>

      <ThumbnailList>
        {images.map((img, idx) => (
          <Thumbnail
            isActive={
              (!isMobile &&
                currentImage === convertImageUrl(img, 1920, 1920)) ||
              (isMobile && imageId === idx)
            }
            onClick={() => {
              if (isMobile) handleChangeImg(idx);
              else handleScrollToImg(img);
            }}
            key={img}
          >
            <ThumbnailImage
              src={convertImageUrl(img, 200, 160)}
              alt="Миниатюра"
            />
          </Thumbnail>
        ))}
      </ThumbnailList>

      <ImageWrapper isMobile={isMobile}>
        {!isMobile &&
          images.map((img, idx) => (
            <MagnifierContainer
              key={idx}
              ref={(el) => (imageRefs.current[idx] = el)}
              className={convertImageUrl(img, 1920, 1920)}
            >
              <MagnifierWrapper
                imageSrc={`${convertImageUrl(img, 1920, 1920)}`}
                largeImageSrc={`${convertImageUrl(img, 3920, 3920)}`}
                mouseActivation={MOUSE_ACTIVATION.CLICK}
                touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
                dragToMove={window.innerWidth < 768}
              />
            </MagnifierContainer>
          ))}
        {isMobile && (
          <MagnifierWrapper
            imageSrc={`${convertImageUrl(images[imageId], 1920, 1920)}`}
            largeImageSrc={`${convertImageUrl(images[imageId], 3920, 3920)}`}
            mouseActivation={MOUSE_ACTIVATION.CLICK}
            touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
            dragToMove={window.innerWidth < 768}
          />
        )}
      </ImageWrapper>
      <MockContainerClick
        disabled={!isMobile}
        isBottom={false}
        onClick={() => handleChangeImgMobile(true)}
      />
      <MockContainerClick
        disabled={!isMobile}
        isBottom
        onClick={() => handleChangeImgMobile(false)}
      />
    </ModalContainer>
  );
};

export default ImageModal;
