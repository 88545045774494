import styled from "styled-components";

import { arrowCauruselPosition } from "../../types";
import { Box } from "../Box";
import { CatalogListItem } from "../CatalogListItem";
import { CustomCarousel } from "../CustomCarousel";

export const Container = styled(Box)`
  width: 100%;

  @media screen and (max-width: 1400px) {
    margin-left: 0px;
  }

  &.favourites {
    display: none;

    @media screen and (max-width: 480px) {
      display: block;
    }

    .slick-slide {
      width: 140px;
      padding: 0;
      height: auto;
      display: flex;
    }

    .slick-slide < div {
      height: auto;
      display: flex;
    }
  }
`;

export const BoxArrow = styled.div<arrowCauruselPosition>`
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: -60px;
  cursor: pointer;
  right: ${({ isRight }): string => (isRight ? "0" : "calc(100% - 30px)")};
  &:hover {
    opacity: 0.8;
  }
  &.disabled {
    cursor: auto;
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width: 768px) {
    bottom: -50px;
  }
`;

export const ProductCarousel = styled(CustomCarousel)`
  .slick-track {
    margin: 0;
    display: flex;
  }
`;

export const ProductItem = styled(CatalogListItem)`
  max-width: 370px;
  padding: 0 6px;

  @media screen and (max-width: 1440px) {
    padding: 0 5px;
  }

  @media screen and (max-width: 600px) {
    padding: 0 7px;
  }

  &.favourites {
    max-width: 370px;
    min-width: 140px;
  }
`;
